import React from "react";
import PropTypes from "prop-types";

class KbygHeader extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { cms_section } = this.props;
    if(cms_section) {
      return (
        <>
          <div className="col-xs-12 sg-kbyg-section-header-section">
            <div className="sg-kbyg-section-header-section-inner">
              <div className="sg-kbyg-header-title sg-kbyg-headline">
                {cms_section.answers.title}
                <br/>
                <br/>
              </div>
              <div className="sg-kbyg-header-text1 sg-kbyg-intro" dangerouslySetInnerHTML={{ __html: cms_section.answers.text1 }}/>
              <div className="sg-kbyg-header-text2 sg-kbyg-body" dangerouslySetInnerHTML={{ __html: cms_section.answers.text2 }}/>
            </div>
          </div>
          <div className="col-xs-12">
            <div className={`sg-kbyg-section-divider`}/>
          </div>
        </>
      )
    }

  }
}

export default KbygHeader;
