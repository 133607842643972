import React from "react";
import GeneralTable from "../GeneralTable";
import Cookies from "universal-cookie";
class ParticipantTicketTable extends React.Component {
  constructor(props) {
    super(props);
    this.updateAttended = this.updateAttended.bind(this);
    this.renderAttendedCheckbox = this.renderAttendedCheckbox.bind(this);
  }
  getTableColumns() {
    const { ticketTypes } = this.props;
    const { user } = this.props;
    let allColumns = [
      { title: 'First', field: 'lower_first_name', render: (row) => row.name_first},
      { title: 'Last', field: 'lower_last_name', render: (row) => row.name_last},
      { title: 'Attendee Type', field: 'attendee_type', render: (row) => row.attendee_type },
      { title: 'Status', field: 'status'},
      {title: 'Attended Event', field: 'attended_event', render: this.renderAttendedCheckbox},
    ]
    // allColumns = allColumns.concat(ticketTypes.map(ticketType => {
    //   return {
    //     title: ticketType.name.replace("Day Program |",""),
    //     field: ticketType.slug,
    //     render: (row) => (
    //       <>
    //       {row.registration_answers[ticketType.slug]||0}
    //       </>
    //     )
    //   }
    // }));
    return allColumns;
  }

  renderAttendedCheckbox(row) {
    let value = row.attended_event;

    return(
      <img
        src={ value  ? "/images/checkbox-checked-1.svg" : "/images/checkbox-unchecked-1.svg" }
        style={{cursor:"pointer", marginBottom: "7px"}}
        onClick={(e) => {
          this.updateAttended(row, !value)
        }}
      />
    )
  }

  updateAttended(row, value) {
    const { updateParticipantCallback } = this.props;
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");
    var form_data = new FormData();
    fetch(`/admin/ticketing/update_user`, {
      method: `POST`,
      redirect: "manual",
      body: JSON.stringify({
        user: {
          email: row.email,
          attended: value
        }
      }),
      headers: {
        "X-CSRF-Token": token,
        "Content-Type": 'application/json'
      }
    }).then(response => {
      return response.json();
    }).then(json => {
      if (json.error == null) {
        if(updateParticipantCallback) {
          updateParticipantCallback(json.participants)
        }
      }
    });
  }
  getTableRegistrants() {
    const { participants } = this.props;
    return participants.map(x=> {
      x.lower_first_name = (x.name_first||"".toLowerCase())
      x.lower_last_name = (x.name_last||"".toLowerCase())
      x.attendee_type = (x.registration_answers.attendeetype)
      x.attended_event = x.attended
      return x
    }).sort((a,b)=>b.id - a.id);
  }


  render() {
    const { ticketTypes, fields, updateParticipantCallback} = this.props;
    return (
      <div className="sg-tickets-table-container">

        <GeneralTable
          columns={this.getTableColumns()}
          data={this.getTableRegistrants()}
        />
      </div>
    )
  }
}

export default ParticipantTicketTable;
