import React from "react";
import PropTypes from "prop-types";
import { Formik, Field, Form } from "formik";
import Cookies from "universal-cookie";
import Select from 'react-select'
import { submitRegistration } from "./RegistrationUtility"
import {timeOptions, seatPreferences, topicsOfInterest, topicsOfInterestAnalyst, sessionFormatOptions, sessionTopicsOptions, arrivalDates, returnDates,theaterOptions, activities, preferredRoomType, travelInformation, getApparelSizeOptions,specialAccomodations, dietaryRestrictions, getCountrySelectOptions, getStateSelectOptions, getTimezoneOptions, getTimezoneInfo, getJobTypes, getPronounOptions}  from "./RegistrationOptions"
import RegistrationInvitationModal from "./RegistrationInvitationModal"
import Creatable, { makeCreatableSelect } from 'react-select/creatable';
import RegistrationPageSignifier from "./partials/RegistrationPageSignifier"
class RegistrationForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      forceAttendeeType: null,
      page: 1,
      pageOneError: null,
      pageThreeError: null,
      pageTwoError: null,
      checkError: false,
      emailTaken: false,
      saving: false,
      features: props.features,
      participant: props.participant
    }
  }

  renderTester() {
    const { participant } = this.props;
    if(participant.test_flag && (participant.email||"").indexOf("@jaguardesignstudio") != -1) {
      const pages = [
        {id: 1},
        {id: 2},
        // {id: 3},
        {id: 4},
      ];

      const types = [
        {id: "Press"},
        {id: "Analyst"}
      ];
      return (
        <div className="sg-reg-page-swapper">
          {pages.map(x=>(
            <div onClick={()=>this.setState({
              page: x.id
            })}className="sg-reg-page-swapper-item">
              Go To Page {x.id}
            </div>
          ))}
          {types.map(x=>(
            <div onClick={()=>this.setState({
              forceAttendeeType: x.id
            })}className="sg-reg-page-swapper-item">
              Change to {x.id}
            </div>
          ))}
        </div>
      )
    }
  }

  pageHiddenClass(renderedPage) {
    const { page } = this.state;
    if(renderedPage == page) {
      return "visible";
    }
    return "hidden";
  }

  isEmailPersonal(email) {
    return false;
  }

  includesPolyfill(string1, string2) {
    return string1.indexOf(string2) != -1
  }

  isPageThreeValid(formikProps) {
    const user = formikProps.values.user;
    if(user.covidattendeeprotocol != "True") {
      return false;
    }
    return true
  }

  isPageTwoValid(formikProps) {
    const { fields, participant } = this.props;
    const { values } = formikProps;
    const { user } = values;
    let fieldList = [
    ];

    if (this.isPressOrAnalyst() === "Press") {
      if(user.topicsofinterest.length > 3) {
        return false
      }
    }
    if (this.isPressOrAnalyst() === "Analyst") {
      // fieldList = fieldList.concat(["topicsofinterestanalyst", "questionsforexecutives"])
      // if(user.topicsofinterestanalyst === 'Other') {
      //   fieldList = fieldList.concat(["topicsofinterestanalystother"])
      // }
      fieldList = fieldList.concat(["questionsforexecutives", "othertopics"])
    }

    if (this.showHotel()) {
      fieldList = fieldList.concat(["ciscoreservehotel"])
    }

    if (this.showFlight()) {
      fieldList = fieldList.concat(["requireairtransportation"])
      if(user.requireairtransportation == "Yes") {
        fieldList = fieldList.concat([
          "transportfromairport",
          "passportfirstname",
          "passportlastname",
          "departuredate",
          "departuretime",
          "departureairport",
          "returnairport",
          "returndate",
          "returntime",
          "seatpreference",
        ]);
        if(user.consentsensitiveinformation != "Yes"){
          return false;
        }
      }
    }
    if(this.isInternational(formikProps)) {
      if(user.embassyrequireinvitation == null) {
        return false;
      }
    }
    if(values.user.waiverandrelease != "True") {
      return false;
    }
    if(values.user.userdataagreement != "True") {
      return false;
    }
    if(user.address_country == "Vietnam" || user.address_country == "South Korea" || user.address_country == "China") {
      if(user.dataprotectionacknowledgement != "True") {
        return false;
      }
      if(user.address_country == "China") {
        if(user.dataprotectionacknowledgement2 != "True") {
          return false;
        }
      }
    }
    
    fieldList = fieldList.filter((x,i,t)=>t.indexOf(x) == i);
    const validFields = fieldList.filter(field => values["user"][field] && values["user"][field].length > 0);

    if (!(validFields.length == fieldList.length)) {
      return false;
    }
    return true;
  }


  isPageOneValid(formikProps) {
    //TODO
    const { fields } = this.props;
    const { values } = formikProps;

    let fieldList = [
      "name_first",
      "name_last",
      "email",
      "company",
      "job_title",
      "work_phone",
      "address_street_1",
      "address_country",
      "address_city",
      "address_postcode",
      "ecname",
      "ecnumber",
    ];

    if(values.user.address_country === "United States") {
      fieldList= fieldList.concat([
          "address_state"
      ])
    }
    if(values.user.specialaccomodations.indexOf("Other")!=-1) {
      fieldList = fieldList.concat(['specialaccomodationsother'])
    }
    if(values.user.dietarypreference.indexOf("Other")!=-1 || values.user.dietarypreference.indexOf("Food Allergies")!=-1) {
      fieldList = fieldList.concat(['dietarypreferenceother'])
    }


    const validFields = fieldList.filter(field => values["user"][field] && values["user"][field].length > 0);
    if (!(validFields.length == fieldList.length)) {
      return false;
    }

    return true;
  }

  renderInterests(formikProps) {
    const { checkError } = this.state;
    const { values } = formikProps;
    const { user } = values;
    return (
      <div className="row" style={{display: "flex", flexWrap: "wrap"}}>
        <div className="col-xs-12 sg-header-copy">
          Your Interests
        </div>
        {
          this.isPressOrAnalyst() === "Analyst" ?
          <>
          {/* <div className="col-xs-12">
            <label className="">
              Please select your top three Roundtable topics:
            </label>
          </div>
          <div className="col-xs-12 col-md-6">
            <label className="required"> Topics </label>
            {this.renderSelectField('topicsofinterestanalyst',formikProps, topicsOfInterestAnalyst())}
          </div>
          {formikProps.values.user.topicsofinterestanalyst == "Other" ? (
          <div className="col-xs-12 col-md-6">
            <label className="required">
              If other, what topic are you interested in?
            </label>
            {this.renderField('topicsofinterestanalystother',formikProps, false)}
          </div>
          ):""} */}
          </>
          :
          <>
          <div className="col-xs-12">
            <label className="required">
              Which of the following topics are you interested in learning about more (please select up to&nbsp;3):
            </label>
          </div>
          {this.renderChooseThree('topicsofinterest', formikProps, topicsOfInterest(this.isPressOrAnalyst()))}
          </>
        }
        {
          this.isPressOrAnalyst() === "Analyst" ?
          <>
            <div className="col-xs-12">
              <label className="required">
                Please list up to three (3) questions for our Executive Team.
              </label>
              {this.renderField('questionsforexecutives',formikProps, false, {component: 'textarea'})}
            </div>
            <div className="col-xs-12">
              <label className="required">
                What other topics would you like to discuss?
              </label>
              {this.renderField('othertopics',formikProps, false, {component: 'textarea'})}
            </div>
          </>
          :
          <>
            {
              user['topicsofinterest'].indexOf("Other") != -1 ?
              <div className="col-xs-12">
                <label className="">
                  What other topics would you like to discuss? (Optional)
                </label>
                {this.renderField('othertopics',formikProps, false, {not_required: true, component: 'textarea'})}
              </div>
              :
              <></>
            }
          </>
        }

        <div className="col-xs-12">
          <div className="att-proam-content-divider"/>
        </div>
      </div>
    )
  }

  renderCustomerAppreciation(formikProps) {
    const { checkError } = this.state;
    const { values } = formikProps;
    return (
      <div className="row">
        <div className="col-xs-12 sg-header-copy">
          Cisco Live Celebration
        </div>
        <div className="col-xs-12 gc-registration-copy">
          <br/>
          We invite you to also attend the Cisco Live Celebration on Wednesday, June 7th from 7:30&nbsp;p.m. until 11:00&nbsp;p.m. at Allegiant Stadium.
          <br/>
          <br/>
          Would you like to attend? *
        </div>
        <div className="col-xs-12">
          {this.renderCheckbox('customerappreciationevent',formikProps, false, {
            forceValue:"True",
          })}<label className="checkbox-label" style={{marginRight:"20px"}}> Yes </label>
          {this.renderCheckbox('customerappreciationevent',formikProps, false, {
            forceValue:"False",
          })}<label className="checkbox-label"> No </label>
          <div className="registration-field-error">
            {checkError && !this.isPageTwoValid(formikProps) && values.user.customerappreciationevent == null ? "Please select an option" : ""}
          </div>
        </div>
        <div className="col-xs-12">
          <div className="att-proam-content-divider"/>
        </div>
      </div>
    )

  }

  renderTravelInformation(formikProps) {
    return (
      <div className="row">
        <div className="col-xs-12 sg-header-copy">
          Travel Information
        </div>
        <div className="col-xs-12 gc-registration-copy">
          <br/>
          <b>Official Arrival Day:</b> Monday, February 5th.
          <br/>
          <i>The first formal business activity begins on Monday, February 5th at 12:00 p.m.</i>
          <br/>
          <br/>
          <div>

          <b>Official Departure Day:</b> Wednesday, February 7th, flights departing after 3:00 p.m.
          <br />
          <i>The last formal business activity concludes at 12:30 p.m.</i>
          </div>

        </div>
        <div className="col-xs-12">
          <div className="att-proam-content-divider"/>
        </div>
      </div>
    )
  }

  renderHotelAccomodations(formikProps) {
    const { checkError } = this.state;
    const { values } = formikProps;

    return (
      <div className="row">
        <div className="col-xs-12 sg-header-copy">
        Hotel Accommodations
        </div>
        <div className="col-xs-12 gc-registration-copy required">
          <br/>
          Would you like Cisco to secure your hotel reservation at the <a href="https://www.okura.nl" target="_blank">Hotel Okura</a>?
        </div>
        <div className="col-xs-12">
          <br/>
          {this.renderCheckbox('ciscoreservehotel',formikProps, false, {
            forceValue:"Yes",
          })}<label className="checkbox-label" style={{marginRight:"20px"}}> Yes </label>
          {this.renderCheckbox('ciscoreservehotel',formikProps, false, {
            forceValue:"No",
          })}<label className="checkbox-label"> No </label>
          <div className="registration-field-error">
            {checkError && !this.isPageTwoValid(formikProps) && values.user.ciscoreservehotel == null ? "Please select an option" : ""}
          </div>
        </div>
        {values.user.ciscoreservehotel == "Yes" ? (
          <div className="col-xs-12 gc-registration-copy">
            <br/>
            We will pay for your accommodations at the Hotel Okura Amsterdam (including rate and tax).
            <br />
            <br />
            We will cover the following hotel nights:
            <br />
            Up to two (2) nights accommodation will be covered by Cisco (February 5th & 6th, with departure on the 7th).
            <br />
            <br />
            Upon check-in, you will be asked to present a credit card. You are liable for any additional room nights and associated incidentals outside of the official program dates.
            <br />
            <br />
            If you need to make changes to your hotel accommodation, please contact <a href="mailto:ciscopress-analyst@cisco.com">ciscopress-analyst@cisco.com</a>.
            <br />
            <br />
          </div>
        ):""}
        {values.user.ciscoreservehotel == "No" ? (
          <div className="col-xs-12 gc-registration-copy">
            <br/>
            Please contact your Cisco PR or AR team member should you have any accommodation requirements.
          </div>
        ):""}
        <div className="col-xs-12">
          <div className="att-proam-content-divider"/>
        </div>
      </div>
    )
  }

  renderFlightAccomodations(formikProps) {
    const { checkError } = this.state;
    const { values } = formikProps;
    // if(!(this.isPressOrAnalyst()=="Press" && !this.isInternational(formikProps))) {
    return (
      <div className="row">
        {/* <div className="col-xs-12 gc-registration-copy">
          Cisco will cover your travel accommodations (flight, ground transfer). Please complete the information below so we may secure your bookings.
          <br />
          <br />
        </div> */}
        <div className="col-xs-12 sg-header-copy">
          Flight Accommodations & Ground Transportation
        </div>
        <div className="col-xs-12 gc-registration-copy required">
          <br/>
          Do you require air transportation?
        </div>
        <div className="col-xs-12">
          <br/>
          {this.renderCheckbox('requireairtransportation',formikProps, false, {
            forceValue:"Yes",
          })}<label className="checkbox-label" style={{marginRight:"20px"}}> Yes </label>
          {this.renderCheckbox('requireairtransportation',formikProps, false, {
            forceValue:"No",
          })}<label className="checkbox-label"> No </label>
          <div className="registration-field-error">
            {checkError && !this.isPageTwoValid(formikProps) && values.user.requireairtransportation == null ? "Please select an option" : ""}
          </div>
        </div>
        {this.renderRequiredFlightAccomodations(formikProps)}
        <div className="col-xs-12">
          <div className="att-proam-content-divider"/>
        </div>
      </div>
    );
    // }
  }
  renderRequiredFlightAccomodations(formikProps) {
    const { checkError } = this.state;
    const { values } = formikProps;
    if(values.user.requireairtransportation == "Yes") {
      return (
        <>
          <div className="col-xs-12 gc-registration-copy">
            <br/>
            Cisco will arrange and pay for your ground transportation from Amsterdam Airport Schiphol to and from the Hotel Okura Amsterdam.
            <br/>
          </div>
          <div className="col-xs-12 gc-registration-copy required">
            <br/>
            Do you require ground transportation to/from the airport?
          </div>
          <div className="col-xs-12">
            <br/>
            {this.renderCheckbox('transportfromairport',formikProps, false, {
              forceValue:"Yes",
            })}<label className="checkbox-label" style={{marginRight:"20px"}}> Yes </label>
            {this.renderCheckbox('transportfromairport',formikProps, false, {
              forceValue:"No",
            })}<label className="checkbox-label"> No </label>
            <div className="registration-field-error">
              {checkError && !this.isPageTwoValid(formikProps) && values.user.transportfromairport == null ? "Please select an option" : ""}
            </div>
          </div>

          <div className="col-xs-12 gc-registration-copy">
            <br/>
            Please complete the following fields so we may begin to book your flight reservations to Amsterdam Airport Schiphol. After completing your registration, a representative from American Express/Cisco Travel will contact you within 4-5 business days of receiving your information.
            <br/>
            <br/>
            If your travel dates differ from below, please proceed to register and notify your representative of any date requirements.
            <br/>
          </div>

          <div className="col-xs-12 col-sm-4">
            <label className="required">Your First Name as it Appears on Your Passport</label>
            {this.renderField('passportfirstname',formikProps)}
          </div>
          <div className="col-xs-12 col-sm-4">
            <label className=""> Your Middle Name as it Appears on Your Passport</label>
            {this.renderField('passportmiddlename',formikProps, false, {not_required: true})}
          </div>
          <div className="col-xs-12 col-sm-4">
            <label className="required"> Your Last Name as it Appears on Your Passport</label>
            {this.renderField('passportlastname',formikProps)}
          </div>
          <div className="col-xs-12 col-sm-6">
            <label className=""> Arrival Day </label>
            {this.renderField('departuredate', formikProps, false, {disabled: 'true'})}
            {/* {this.renderSelectField('departuredate',formikProps, arrivalDates(formikProps))} */}
          </div>
          <div className="col-xs-12 col-sm-6">
            <label className="required">Preferred Arrival Time</label>
            {/* {this.renderSelectField('departuretime',formikProps, timeOptions())} */}
            {this.renderField('departuretime',formikProps, false, {type: "time"})}
          </div>
          <div className="col-xs-12 col-sm-6">
            <label className="required"> Preferred Departure Airport</label>
            {this.renderField('departureairport',formikProps)}
          </div>
          <div className="col-xs-12 col-sm-6">
            <label className="required">Preferred Returning Airport</label>
            {this.renderField('returnairport',formikProps)}
          </div>
          <div className="col-xs-12 col-sm-6">
            <label className=""> Departure Day</label>
            {this.renderField('returndate', formikProps, false, {disabled: 'true'})}
            {/* {this.renderSelectField('returndate',formikProps, returnDates(formikProps))} */}
          </div>
          <div className="col-xs-12 col-sm-6">
            <label className="required">Preferred Return Time</label>
            {/* {this.renderSelectField('returntime',formikProps, timeOptions())} */}
            {this.renderField('returntime',formikProps, false, {type: "time"})}
          </div>
          <div className="col-xs-12 col-sm-6">
            <label className="required">Seat Preference</label>
            {this.renderSelectField('seatpreference',formikProps, seatPreferences(),false)}
          </div>
          <div className="col-xs-12"/>

          <div className="col-xs-12 col-sm-6">
            <label className="">Frequent Flyer Airline</label>
            {this.renderField('frequentyflyerairline',formikProps, false, {not_required: true})}
          </div>
          <div className="col-xs-12 col-sm-6">
            <label className="">Frequent Flyer Number</label>
            {this.renderField('frequentyflyermembership',formikProps, false, {not_required: true})}
          </div>
          <div className="col-xs-12">
            <label className="">If you have any special transportation needs, please note them here</label>
            {this.renderField('specialtransportationneeds',formikProps, false, {not_required: true, component: "textarea"})}
          </div>

          <div className="col-xs-12 gc-registration-copy">
            <br/>
            Do you consent to the use of the information provided here for the purpose of booking travel and accommodations for the Press and Analyst Conference at Cisco Live 2024?
          </div>
          <div className="col-xs-12">
            <br/>
            {this.renderCheckbox('consentsensitiveinformation',formikProps, false, {
              forceValue:"Yes",
            })}<label className="checkbox-label"> Yes, I consent. </label>
            <div className="registration-field-error">
              {checkError && !this.isPageTwoValid(formikProps) && values.user.consentsensitiveinformation !="Yes" ? "Please agree to continue" : ""}
            </div>
          </div>
        </>
      )
    }
  }

  departureDates() {
    return [
      "Wednesday, November 2",
      "Thursday, November 3"
    ].map(x=>{return{value:x, label: x}})
  }

  renderPartnerSummitWaiver(formikProps) {
    const { checkError } = this.state;
    const { values } = formikProps;
    return (
      <>
      <div className="row">
        <div className="col-xs-12 sg-header-copy">
        Cisco Live Waiver & Release
        </div>
        <div className="col-xs-12 gc-registration-copy required">
          <br/>
          <a href="/registration/cl-emea-waiver-and-release.pdf" target="_blank">View the Cisco Live Waiver & Release</a>
          <br/>
          <br/>
          Do you agree to the Cisco Live Waiver & Release?
        </div>
        <div className="col-xs-12">
          <br/>
          {this.renderCheckbox('waiverandrelease',formikProps)}
          <label className="checkbox-label" for="topic_ids" style={{verticalAlign:"middle", lineHeight:"19px"}}>Yes, I agree.</label>
          <div className="registration-field-error">
            {checkError && !this.isPageTwoValid(formikProps) && values.user.waiverandrelease != "True" ? "Please click to agree." : ""}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          <div className="att-proam-content-divider"/>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 sg-header-copy">
        Cisco Data Use Agreement
        </div>
        <div className="col-xs-12 gc-registration-copy required">
          <br/>
          I have read and I acknowledge that my data will be processed according to the <a href="/registration/cl-emea-data-use-agreement.pdf" target="_blank">User Data Agreement</a> and the <a href="https://www.cisco.com/go/privacy" target="_blank">Cisco Online Privacy Statement</a>.
        </div>
        <div className="col-xs-12">
          <br/>
          {this.renderCheckbox('userdataagreement',formikProps)}
          <label className="checkbox-label" for="topic_ids" style={{verticalAlign:"middle", lineHeight:"19px"}}>Yes, I agree.</label>
          <div className="registration-field-error">
            {checkError && !this.isPageTwoValid(formikProps) && values.user.userdataagreement != "True" ? "Please click to agree." : ""}
          </div>
        </div>
      </div>
      </>

    )
  }

  isInternational(formikProps) {
    const { participant } = this.props;
    if((participant['tags']||[]).map(x=> x['name']).indexOf('Force International') != -1) {
      return true
    }
    if((participant['tags']||[]).map(x=> x['name']).indexOf('Force Local') != -1) {
      return false
    }

    return formikProps.values.user.address_country != "Australia";
  }

  isPressOrAnalyst() {
    //todo
    const { forceAttendeeType } = this.state;
    const { participant } = this.props;
    if(forceAttendeeType) {
      return forceAttendeeType;
    }
    return participant.type;
  }

  showHotel() {
    const { participant } = this.props;
    return participant.tags.filter(tag => tag.name === "No Hotel").length === 0
  }
  showFlight() {
    const { participant } = this.props;
    return participant.tags.filter(tag => tag.name === "No Flight").length === 0
  }

  renderVisaInformation(formikProps) {
    const { checkError } = this.state;
    const { values } = formikProps;
    if(this.isInternational(formikProps)) {
      return (
        <div className="row">
          <div className="col-xs-12 sg-header-copy">
        Visa Information
          </div>
          <div className="col-xs-12 gc-registration-copy required">
            <br/>
            Does your Embassy require a letter of invitation to obtain a visa for the Netherlands?
          </div>
          <div className="col-xs-12">
            <br/>
            {this.renderCheckbox('embassyrequireinvitation',formikProps, false, {
              forceValue:"True",
            })}<label className="checkbox-label" style={{marginRight:"20px"}}> Yes </label>
            {this.renderCheckbox('embassyrequireinvitation',formikProps, false, {
              forceValue:"False",
            })}<label className="checkbox-label"> No </label>
            <div className="registration-field-error">
              {checkError && !this.isPageTwoValid(formikProps) && values.user.embassyrequireinvitation == null ? "Please select an option" : ""}
            </div>
          </div>
          {values.user.embassyrequireinvitation == "True" ? (
            <>
              <div className="col-xs-12 gc-registration-copy">
                A representative from Cisco Live team will contact you within 2 business days.
              </div>
            </>
          ):""}
          <div className="col-xs-12">
            <div className="att-proam-content-divider"/>
          </div>
        </div>
      )
    }
  }

  renderPageTwo(formikProps) {
    const { participant, fields } = this.props;
    const { checkError, fullPageError, pageTwoError } = this.state;
    const { values } = formikProps;

    return (
      <div className={`registration-page-one ${this.pageHiddenClass(2)}`}>
        {this.renderInterests(formikProps)}
        {this.renderTravelInformation(formikProps)}
        {/* {this.renderCustomerAppreciation(formikProps)} */}
        {
          this.showHotel() ?
          this.renderHotelAccomodations(formikProps)
          :
          <></>
        }
        {
          this.showFlight() ?
          this.renderFlightAccomodations(formikProps)
          :
          <></>
        }
        {this.renderVisaInformation(formikProps)}
        {this.renderPartnerSummitWaiver(formikProps)}
        {this.renderDataProtection(formikProps)}
        <div className="row">
          <div className="col-xs-12">
            <div className="att-proam-content-divider"/>
          </div>
        </div>
        {this.renderLatestOffersAndPromotions(formikProps)}

        <div className="row">
          <div className="col-xs-12 text-center">
            <div className="registration-field-error text-center">
              {checkError && !this.isPageTwoValid(formikProps) ? pageTwoError : ""}
              {fullPageError}
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderPageOne(formikProps) {
    const { fields, participant } = this.props;
    const { values } = formikProps;
    const { user } = values;
    const { pageOneError, emailTaken, checkError, pageThreeError, fullPageError } = this.state;
    return (
      <>
      <div className={`registration-page-one ${this.pageHiddenClass(1)}`}>
        <div className="row">
          <div className="col-xs-12 sg-header-copy">
            Your Information
          </div>
          <div className="col-xs-12 col-sm-6">
            <label className="required">First Name</label>
            {this.renderField('name_first',formikProps)}
          </div>
          <div className="col-xs-12 col-sm-6">
            <label className="required"> Last Name</label>
            {this.renderField('name_last',formikProps)}
          </div>
          <div className="col-xs-12 col-sm-6">
            <label className=""> Preferred First Name on Badge (optional) </label>
            {this.renderField('preferredname',formikProps, false, {not_required: true})}
          </div>
          <div className="col-xs-12 col-sm-6">
            <label className="required"> Email Address </label>
            {this.renderField('email',formikProps,false,{disabled: true})}
            {emailTaken ? (
              <div className="registration-field-error">
                 This email has already been taken.
              </div>
            ): ""}
          </div>
          <div className="col-xs-12 col-sm-6">
            <label className="required"> Firm Name </label>
            {this.renderField('company',formikProps)}
          </div>
          <div className="col-xs-12 col-sm-6">
            <label className="required"> Job Title </label>
            {this.renderField('job_title',formikProps)}
          </div>
          <div className="col-xs-12 col-sm-6">
            <label className="required"> Mobile Telephone (including area and country code)</label>
            {this.renderField('work_phone',formikProps)}
          </div>
          <div className="col-xs-12"/>

          <div className="col-xs-12">
          </div>
          {this.renderAddressForm(formikProps)}
          <div className="col-xs-12">
            <div className="att-proam-content-divider"/>
          </div>
          <div className="col-xs-12 sg-header-copy">
            Emergency Contact
          </div>
          <div className="col-xs-12 col-sm-6">
            <label className="required"> Emergency Contact Name </label>
            {this.renderField('ecname',formikProps)}
          </div>
          <div className="col-xs-12 col-sm-6">
            <label className="required"> Emergency Contact Phone Number</label>
            {this.renderField('ecnumber',formikProps)}
          </div>

          <div className="col-xs-12">
            <div className="att-proam-content-divider"/>
          </div>
          <div className="col-xs-12 sg-header-copy">
            Accommodations (multi-select)
          </div>
          <div className="col-xs-12 gc-registration-copy">
            <br/>
            Do you require additional accommodations?
          </div>
          <div className="col-xs-12">
            <div className="row">
              {specialAccomodations(fields).map(x=>
                <div className="col-xs-12 col-sm-4">
                  {this.renderAdditiveCheckbox('specialaccomodations',x.value, x.label, formikProps)}
                </div>
              )}
            </div>
          </div>
          {values.user.specialaccomodations.indexOf('Other') != -1 ? (
            <div className="col-xs-12 col-md-6">
              <label className="required"> Please specify: </label>
              {this.renderField('specialaccomodationsother',formikProps, false)}
            </div>
          ) : ""}
          <div className="col-xs-12"></div>
          <div className="col-xs-12">
            <div className="att-proam-content-divider"/>
          </div>
          <div className="col-xs-12 sg-header-copy">
            Dietary Preferences (multi-select)
          </div>
          <div class="col-xs-12 gc-registration-copy">
            <br/>
            Cisco Live will be incorporating the following dietary preferences into the main menus: vegetarian, vegan, gluten-free, dairy-free, and gluten-free/dairy-free. All selections will be clearly labeled at every meal to indicate if they are vegetarian, vegan, and/or contain dairy, eggs, fish, shellfish, tree nuts, peanuts, wheat, soybeans, sesame or pork. If you have any of the dietary preferences listed below please let us know.
          </div>
          <div className="col-xs-12">
            <div className="row">
              {dietaryRestrictions(fields).map(x=>
                <div className="col-xs-12 col-sm-6 col-md-4">
                  {this.renderAdditiveCheckbox('dietarypreference',x.value, x.label, formikProps)}
                </div>
              )}
            </div>
          </div>
          {values.user.dietarypreference.indexOf('Food Allergies') != -1 || values.user.dietarypreference.indexOf('Other') != -1 ? (
            <div className="col-xs-12 col-md-6">
              <label className="required"> Please specify: </label>
              {this.renderField('dietarypreferenceother',formikProps, false)}
            </div>
          ) : ""}
          <div className="col-xs-12 text-center">
            <div className="registration-field-error text-center">
              {checkError && !this.isPageOneValid(formikProps) ? pageOneError : ""}
              {fullPageError}
            </div>
          </div>
        </div>
      </div>
      </>
    )
  }

  renderChooseThree(name, formikProps, options) {
    const { checkError } = this.state;
    const { values } = formikProps;
    const { user } = values;
    const optionsRendered = options.map(x=>
      <>
        {/* <div className="col-xs-12" style={{fontWeight: "600", marginTop: "10px"}}>{x.name}</div> */}
        {
          x.values.map(v =>
            <div className="col-xs-12 col-sm-6" style={{paddingTop: "10px"}}>
              {this.renderChooseThreeCheckbox(name, v.label, formikProps)}
            </div>
          )
        }
      </>
    )
    return (
      <>
        {optionsRendered}
        {/* {user[name].indexOf("Other") != -1 ? (
          <div className="col-xs-12">
            {this.renderField(`${name}other`,formikProps, false, {
              component: "textarea",
              placeholder: "If other, what topics are you interested in?"
            })}
          </div>
        ):""} */}
        {checkError && (user[name]||[]).length == 0 ? (
          <div className="col-xs-12">
            <div className="registration-field-error" style={{marginTop: 0}}>
            Please select at least one option
            </div>
          </div>
        ):""}
        {/* {this.isPressOrAnalyst()!="Press" && checkError && (user[name]||[]).length < 5 ? (
          <div className="col-xs-12">
            <div className="registration-field-error" style={{marginTop: 0}}>
            Please select 2–3 options
            </div>
          </div>
        ):""} */}
      </>
    )
  }

  renderAdditiveCheckbox(name,value, label, formikProps) {
    const { values } = formikProps;
    const { user } = values;
    let checked = false;
    const renderOptions = {}
    let nameValues = []
    try {
      nameValues = user[name]
      checked = nameValues.indexOf(value) != -1
    }catch(e){

    }
    return(
      <>
      <br/>
      <img
        src={ checked  ? "/images/checkbox-checked-1.svg" : "/images/checkbox-unchecked-1.svg" }
        style={{cursor:"pointer", marginBottom: "7px"}}
        onClick={(e) => {
          const { user } = formikProps.values;
          console.log({checked, nameValues});
          if(checked) {
            user[name] = nameValues.filter(x=>x != value)
          } else {
            user[name] = nameValues.concat([value])
          }
          formikProps.setFieldValue('user', user)
        }}
      />
        <label className="checkbox-label" for="topic_ids" style={{verticalAlign:"middle", lineHeight:"19px"}}>{label}</label>
      </>
    )
  }

  renderChooseThreeCheckbox(name,label, formikProps) {
    const { values } = formikProps;
    const { user } = values;
    let checked = false;
    const renderOptions = {}
    try {
      checked = user[name].indexOf(label) != -1
    }catch(e){

    }
    return(
      <>
      <img
        src={ checked  ? "/images/checkbox-checked-1.svg" : "/images/checkbox-unchecked-1.svg" }
        style={{cursor:"pointer", marginBottom: "7px"}}
        onClick={(e) => {
          const { user } = formikProps.values;
          if(checked) {
            user[name] = (user[name]||[]).filter(x=>x != label);
          }else {
            let maxCount = 3
            // if(this.isPressOrAnalyst()=="Press"){
            //   maxCount = 4
            // }
            if((user[name]||[]).length < maxCount){
              user[name] = (user[name]||[]).concat([label])
            }
          }
          formikProps.setFieldValue('user', user)
        }}
      />
        <label className="checkbox-label" for="topic_ids" style={{verticalAlign:"middle", lineHeight:"19px"}}>{label}</label>
      </>
    )
  }

  renderGuestFields(formikProps) {
    const { values } = formikProps;
    if(values.user.bringingguest == "True") {
      return (
        <>
          <div className="col-xs-12 col-sm-6">
            <label className="required"> Guest First Name </label>
            {this.renderField('guestnamefirst',formikProps)}
          </div>
          <div className="col-xs-12 col-sm-6">
            <label className="required"> Guest Last Name</label>
            {this.renderField('guestnamelast',formikProps)}
          </div>
          <div className="col-xs-12 col-sm-6">
            <label className="required"> Guest Email</label>
            {this.renderField('guestemail',formikProps)}
          </div>
          <div className="col-xs-12 col-sm-6">
            <label className="required"> Guest Apparel Size </label>
            {this.renderSelectField('guestapparelsize',formikProps, getApparelSizeOptions())}
          </div>
        </>
      )
    }
  }

  renderAddressForm(formikProps) {
    const { values } = formikProps;
    const { user } = values;
    return(
      <>
      <div className="col-xs-12 col-sm-6">
        <label className="required"> Address 1 </label>
        {this.renderField('address_street_1',formikProps)}
      </div>
      <div className="col-xs-12 col-sm-6">
        <label className=""> Address 2 (optional) </label>
        {this.renderField('address_street_2',formikProps, false, {not_required: true})}
      </div>
      <div className="col-xs-12 col-sm-6">
        <label className="required"> Country </label>
        {this.renderSelectField('address_country',formikProps, getCountrySelectOptions())}
      </div>
      {user.address_country=="United States" ? (
        <div className="col-xs-12 col-sm-6">
          <label className="required"> State </label>
          {this.renderSelectField('address_state',formikProps, getStateSelectOptions())}
        </div>
      ) : (
        <div className="col-xs-12 col-sm-6">
          <label className=""> Province/County (Optional) </label>
          {this.renderField('address_state',formikProps, false, {not_required: true})}
        </div>
      )}

      <div className="col-xs-12 col-sm-6">
        <label className="required"> City </label>
        {this.renderField('address_city',formikProps)}
      </div>

      <div className="col-xs-12 col-sm-6">
        <label className="required"> Postal Code </label>
        {this.renderField('address_postcode',formikProps)}
      </div>
      </>
    )
  }


  renderField(name,formikProps, customValidation = false, properties = {}) {
    const { checkError } = this.state;
    let erroring = checkError && (formikProps.values.user[name] == undefined || formikProps.values.user[name].length <= 0 || customValidation);
    if(properties['not_required']) {
      erroring = false || customValidation;
    }
    return (
      <Field
        disabled={properties['disabled']}
        onKeyUp={() => properties['onKeyUp'](formikProps)}
        component={properties['component']}
        className={`form-control ${erroring ? 'field-erroring': "" }`}
        type={properties['type']}
        name={`user[${name}]`}
        autocomplete="off"
        placeholder={properties['placeholder']||""}
      />
    )
  }

  renderSelectField(name, formikProps, options, customValidation = false, renderOptions = {}) {
    const { checkError } = this.state;
    const erroring = !renderOptions['not_required'] && checkError && ((formikProps.values.user[name] == null || formikProps.values.user[name].length <= 0) || customValidation);
    let value = null;
    try {
       value = options.filter(option => option.value === formikProps.values['user'][name])[0]
    }catch (e) {

    }
    return (
      <Select
        className={erroring ? "registration-select-erroring" : ""}
        options={options}
        classNamePrefix='registration-select'
        defaultValue={{value:"Unassigned",label:renderOptions['placeholder']||""}}
        value={options ? value : ""}
        onChange={(option) => {
          const { user } = formikProps.values;
          user[name] = option.value
          formikProps.setFieldValue('user', user)
        }}
         isClearable={false}
        clearable={false}
        filterOption={this.customFilter}
      />
    )
  }

  renderMultiSelectField(name, formikProps, options, renderOptions = {}) {
    const { checkError } = this.state;
    const erroring = !renderOptions['not_required'] && checkError && ((formikProps.values.user[name] == null || formikProps.values.user[name].length <= 0));
    let value = [];
    try {
       value = (formikProps.values['user'][name]||"").split(";").filter(x=>x).map(x=>{return {label: x, value: x}});
    }catch (e) {

    }
    return (
      <Select
        className={erroring ? "registration-select-erroring" : ""}
        options={options}
        classNamePrefix='registration-select'
        defaultValue={{value:"Unassigned",label:renderOptions['placeholder']||""}}
        value={options ? value : ""}
        onChange={(option) => {
          const { user } = formikProps.values;
          user[name] = option.map(x=>x.value).join(";")
          formikProps.setFieldValue('user', user)
        }}
        clearable={false}
        isMulti={true}
        filterOption={this.customFilter}
      />
    )
  }

  renderCheckbox(name, formikProps, customValidation=false, renderOptions={}) {
    let value = false;
    try {
      value = formikProps.values['user'][name] == "True";
    }catch(e){

    }
    if(renderOptions['forceValue'] != undefined ) {
      value = formikProps.values['user'][name] == renderOptions['forceValue']
    }
    let images = {
      unchecked: "/images/checkbox-unchecked-1.svg",
      checked: "/images/checkbox-checked-1.svg"
    }
    if(renderOptions.checkedImage) {
      images.checked = renderOptions.checkedImage
    }
    if(renderOptions.uncheckedImage) {
      images.unchecked = renderOptions.uncheckedImage
    }
    return(
      <img
        src={ value  ? images.checked : images.unchecked }
        style={{cursor:"pointer", marginBottom: "7px", height: "21px"}}
        onClick={(e) => {
          const { user } = formikProps.values;
          if(renderOptions['forceValue'] != undefined) {
            user[name] = renderOptions['forceValue']
          }else {
            user[name] = value ? "False" : "True"
          }

          formikProps.setFieldValue('user', user)
        }}
      />
    )
  }

  customFilter(option, searchText) {
    const ones =  option.label.toLowerCase().split(" ").filter(x=> x.startsWith(searchText.toLowerCase()));
    return ones.length > 0;
  }

  renderCovidAttendeeProtocol(formikProps) {
    const { values } = formikProps;
    const { checkError } = this.state;
    return (
      <div className="row">
        <div className="col-xs-12 sg-header-copy">
          COVID-19 Attendee Protocol
        </div>
        <div className="col-xs-12 gc-registration-copy">
          <br/>
          View the{" "}
          <a href="https://www.ciscolive.com/global/attend/attendee-info/health-safety.html" target="_blank">
            Health & Safety Policies
          </a>
          <br/>
          <br/>
          By registering to attend this event in-person, I acknowledge Health & Safety Protocols are subject to change, and I agree to abide by the Health & Safety Policies required by Cisco and/or the event venue at the time of the event. I understand I may be denied access to, or removed from, the event if I do not comply.
        </div>
        <div className="col-xs-12">
          <br/>
          {this.renderCheckbox('covidattendeeprotocol',formikProps)}
          <label className="checkbox-label" for="topic_ids" style={{verticalAlign:"middle", lineHeight:"19px"}}> Yes, I agree.</label>
          <div className="registration-field-error">
            {checkError && !this.isPageThreeValid(formikProps) && values.user.covidattendeeprotocol != "True" ? "Please click to agree." : ""}
          </div>
        </div>
        <div className="col-xs-12">
          <div className="att-proam-content-divider"/>
        </div>
      </div>
    )
  }

  renderLatestOffersAndPromotions(formikProps) {
    const { values } = formikProps;
    const { checkError } = this.state;
    return (
      <div className="row">
        <div className="col-xs-12 sg-header-copy">
          Latest Offers and Promotions
        </div>
        <div className="col-xs-12 gc-registration-copy">
          <br/>
          Cisco would like to use your information above to provide you with the latest offers, promotions, and news regarding Cisco products and services. You can unsubscribe at any time.
        </div>
        <div className="col-xs-12">
          <br/>
          {this.renderCheckbox('ciscoemailuseconsent',formikProps)}
          <label className="checkbox-label" for="topic_ids" style={{verticalAlign:"middle", lineHeight:"19px"}}>Yes, I would like to be contacted by email.</label>
        </div>

        <div className="col-xs-12 gc-registration-copy">
          <br/>
          Do you consent to the use of your registration information by event sponsors to contact you with offers, promotions, and news about their products and services?
        </div>
        <div className="col-xs-12">
          <br/>
          {this.renderCheckbox('sponsoremailuseconsent',formikProps)}
          <label className="checkbox-label" for="topic_ids" style={{verticalAlign:"middle", lineHeight:"19px"}}>Yes, I consent.</label>
        </div>
      </div>
    )
  }

  requiresDataProtectionAcknowledgement(formikProps) {
    //TODO only Vietnam and Korea
    const { values } = formikProps;
    const { user } = values;
    const { address_country } = user;
    if(address_country == "Vietnam" || address_country == "South Korea" || address_country == "China") {
      return true;
    }
    return false;
  }

  renderDataProtection(formikProps) {
    const { values } = formikProps;
    const { checkError } = this.state;
    if(this.requiresDataProtectionAcknowledgement(formikProps)){
      if (values.user.address_country == "Vietnam" || values.user.address_country == "South Korea") {
        return (
          <div className="row">
            <div className="col-xs-12">
              <div className="att-proam-content-divider"/>
            </div>
            <div className="col-xs-12 sg-header-copy">
              Cisco Data Protection Acknowledgement
            </div>
            <div className="col-xs-12 gc-registration-copy">
              <br/>
              Cisco may process my personal information, including transferring my personal information internationally, as described in its <a href="https://www.cisco.com/go/privacy">Online Privacy Statement</a>. *
            </div>
            <div className="col-xs-12 gc-registration-copy">
              <br/>
              Cisco는 온라인 개인 정보 보호 정책에 설명된 대로 내 개인 정보를 국제적으로 전송하는 것을 포함하여 내 개인 정보를 처리할 수 있습니다. *
            </div>
            <div className="col-xs-12 gc-registration-copy">
              <br/>
              Cisco có thể xử lý thông tin cá nhân của tôi, bao gồm việc chuyển thông tin cá​nhân ra quốc tế, như được mô tả trong Tuyên bố về quyền riêng tư trực tuyến.​
            </div>
            <div className="col-xs-12">
              <br/>
              {this.renderCheckbox('dataprotectionacknowledgement',formikProps)}
              <label className="checkbox-label" for="topic_ids">
                Yes, I consent. 네, 동의합니다. Vâng, tôi đồng ý.
                </label>
              <div className="registration-field-error">
                {checkError && !this.isPageTwoValid(formikProps) && values.user.dataprotectionacknowledgement != "True" ? "Please click to agree." : ""}
              </div>
            </div>
            <br />
          </div>
        );
      } else {
        return (
          <div className="row">
            <div className="col-xs-12">
              <div className="att-proam-content-divider"/>
            </div>
            <div className="col-xs-12 sg-header-copy">
              Cisco Data Protection Acknowledgement
            </div>
            <div className="col-xs-12 gc-registration-copy">
              <br/>
              Do you consent to Cisco’s collection and use of your personal information, including disclosure to third parties, in connection with this event? <a href="https://www.cisco.com/go/privacy">Learn More</a>
            </div>
            <div className="col-xs-12 gc-registration-copy">
              <br/>
              您是否同意思科收集和使用与本次活动相关的您的个人信息？<a href="https://www.cisco.com/go/privacy">了解更多信息</a>
            </div>
            <div className="col-xs-12">
              <br/>
              {this.renderCheckbox('dataprotectionacknowledgement',formikProps)}
              <label className="checkbox-label" for="topic_ids">
                Yes, I consent. 是的，我同意. *
                </label>
              <div className="registration-field-error">
                {checkError && !this.isPageTwoValid(formikProps) && values.user.dataprotectionacknowledgement != "True" ? "Please click to agree." : ""}
              </div>
            </div>

            <div className="col-xs-12 gc-registration-copy">
              <br/>
              Do you consent to the transfer of your registration information outside of Mainland China? <a href="https://www.cisco.com/go/privacy">Learn More</a>
            </div>
            <div className="col-xs-12 gc-registration-copy">
              <br/>
              “我同意为了信息处理将个人信息传输到中国大陆以外的地区。 <a href="https://www.cisco.com/go/privacy">了解详情</a>
            </div>
            <div className="col-xs-12">
              <br/>
              {this.renderCheckbox('dataprotectionacknowledgement2',formikProps)}
              <label className="checkbox-label" for="topic_ids">
                Yes, I consent. 是的，我同意. *
                </label>
              <div className="registration-field-error">
                {checkError && !this.isPageTwoValid(formikProps) && values.user.dataprotectionacknowledgement2 != "True" ? "Please click to agree." : ""}
              </div>
            </div>
            <br />
          </div>
        )
      }
    }
  }

  renderPageThree(formikProps) {
    const { participant } = this.props;
    const { checkError, pageThreeError, fullPageError} = this.state;
    return (
      <div className={`registration-page-one ${this.pageHiddenClass(3)}`}>
        {this.renderCovidAttendeeProtocol(formikProps)}
        {/* {this.renderDataProtection(formikProps)} */}
        {this.renderLatestOffersAndPromotions(formikProps)}
        <div className="row">
          <div className="col-xs-12 text-center">
            <div className="registration-field-error text-center">
              {checkError && !this.isPageThreeValid(formikProps) ? pageThreeError : ""}
              {fullPageError}
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderPageFour(formikProps) {
    const { participant } = this.props;
    return (
      <div className={`registration-page-one ${this.pageHiddenClass(4)}`}>
        <div className="row">
          <div className="col-xs-12 att-proam-registration-section-copy">
            If you have any questions regarding the registration process, please contact us.
            <br/>
            <br/>
            <div className="">
              <br/>
              <br/>
              <a target="_blank" href="mailto:ciscopress-analyst@cisco.com">
                <button type="button" style={{paddingTop: "10px", marginBottom: "30px"}} className="sg-round-button sg-primary-button">
                  Contact
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderHeader(formikProps) {
    const { page } = this.state;
    return (
      <div className="att-proam-registration-header">
        <div className="sg-header-copy">
          We look forward to welcoming you to the Press and Analyst Conference at Cisco Live Amsterdam 2024.
        </div>
        {page == 1 ? (
          <div className="sg-copy">
            <br/>
            Please complete the following form. Once the completed form has been submitted, you will receive a confirmation email. If you have any questions regarding the registration process, please contact  <a href="mailto:ciscopress-analyst@cisco.com">ciscopress-analyst@cisco.com</a>
          </div>
        ):""}
        <RegistrationPageSignifier page={page} changePage={()=>{console.log("TODO:Change page")}}/>
      </div>
    )
  }

  renderButtons(formikProps) {
    const { page } = this.state;
    if(page < 4) {
      return (
        <div className="registration-page-one visible">
          <div className="row">
            <div className="col-xs-12">
              <br/>
            </div>
            <div className="col-xs-6">
              {page > 1 ? (
                <button onClick={()=>this.prevPage(formikProps)} className="sg-round-button sg-secondary-button">
                  Previous Step
                </button>
              ):""}
            </div>
            <div onClick={()=>this.nextPage(formikProps)} className="col-xs-6 text-right">
              <button className="sg-round-button sg-primary-button">
                {page == 2 ? "Register" : "Continue"}
              </button>
            </div>
            <div className="col-xs-12">
              <br/>
              <br/>
            </div>
          </div>
        </div>

      )
    }
  }

  nextPage(formikProps = {}){
    const { page }  = this.state;
    switch(page) {
      case 1:
        if(!this.isPageOneValid(formikProps)){
          this.setState({
            pageOneError: "Please make sure all fields have been properly filled in",
            checkError: true,
          })
          return;
        }
        break;
      case 2:
        if(!this.isPageTwoValid(formikProps)){
          this.setState({
            pageTwoError: "Please make sure all fields have been properly filled in",
            checkError: true,
          })
          return;
        } else {
          formikProps.submitForm();
          return;
        }
        break;
      case 3:
        if(!this.isPageThreeValid(formikProps)){
          this.setState({
            pageThreeError: "Please make sure all fields have been properly filled in",
            checkError: true
          })
          return;
        }else {
          formikProps.submitForm();
          return;
        }
        break;
      default:
        break;
    }
    window.scrollTo(0,0);
    this.setState({
      page: page === 2 ? 4 : page + 1,
      checkError: false,
      pageTwoError: null,
      pageOneError: null,
      pageThreeError: null,
    })
  }

  prevPage(formikProps){
    const { page }  = this.state;
    window.scrollTo(0,0);
    this.setState({
      page: page - 1,
      fullPageError: null,
      checkError: false
    })
  }

  render() {
    const { user, participant} = this.props;
    return (
      <div className="registration-form">
        {this.renderTester()}
        <Formik
          initialValues={{
            user: {
              name_first: participant ? participant.name_first : "",
              name_last: participant ? participant.name_last : "",
              preferredname: "",
              email: participant ? participant.email : "",
              company: user ? user.company : "",
              job_title: user ? user.job_title : "",
              work_phone: "",
              address_street_1: participant ? participant.address_street_1 : "",
              address_street_2: participant ? participant.address_street_2 : "",
              address_country: participant ? participant.address_country : "",
              address_state: participant ? participant.address_state : "",
              address_city: participant ? participant.city : "",
              address_postcode: participant ? participant.address_postcode : "",
              ecname:"",
              ecnumber:"",
              specialaccomodations: [],
              specialaccomodationsother: "",
              dietarypreference: [],
              dietarypreferenceother:"",

              topicsofinterest: [],
              othertopics: "",
              topicsofinterestanalyst: "",
              topicsofinterestanalystother: "",
              questionsforexecutives: "",
              ciscoreservehotel: null,
              requireairtransportation: null,
              transportfromairport: null,
              passportfirstname:"",
              passportmiddlename:"",
              passportlastname:"",
              departuredate: "Monday, February 5th",
              departuretime: "",
              departureairport:"",
              returnairport:"",
              returndate: "Wednesday, February 7th",
              returntime: "",
              seatpreference:"",
              frequentyflyerairline:"",
              frequentyflyermembership:"",
              specialtransportationneeds: "",
              consentsensitiveinformation: "",
              embassyrequireinvitation: null,
              waiverandrelease: null,
              userdataagreement: null,
              dataprotectionacknowledgement: null,
              dataprotectionacknowledgement2: null,

              covidattendeeprotocol: null,
              ciscoemailuseconsent: null,
              sponsoremailuseconsent: null
            },
          }}
          onSubmit={(values, actions) => {
            submitRegistration(values, this.state, this)
          }}
          render={(formikProps) => (
            <Form onSubmit={e => { e.preventDefault(); e.stopPropagation(); }} id="user-registration-form" style={{border: "0"}}>
              {this.renderHeader(formikProps)}
              {this.renderPageOne(formikProps)}
              {this.renderPageTwo(formikProps)}
              {this.renderPageThree(formikProps)}
              {this.renderPageFour(formikProps)}
              {this.renderButtons(formikProps)}
            </Form>
        )}/>
      </div>
    );
  }
}

export default RegistrationForm;
