import React from "react";
import PropTypes from "prop-types";
import { format, isSameDay, toDate, parse, addMinutes, compareAsc, getMinutes } from 'date-fns'
import ReactTooltip from "react-tooltip";
import Cookies from "universal-cookie";
import   { formatToTimeZone, zonedTimeToUtc, utcToZonedTime } from 'date-fns-timezone';
import { canSessionBeAdded, isInTrack } from "@utility/SessionUtils";
import Table from "rc-table";
import "chart.js";
import { LineChart, AreaChart, ColumnChart, BarChart } from "react-chartkick";
import GeneralTable from "./GeneralTable"
import { reportsList } from "./platform_reports/platformReportsUtility"
import AdminOpenablePanel from "./platform_reports/AdminOpenablePanel"
import ReportBubble from "./platform_reports/ReportBubble"
import CustomReportTable from "./platform_reports/CustomReportTable"
import MailActivityReports from "./platform_reports/mail/MailActivityReports"
import EmailSectionStats from "./platform_reports/mail/EmailSectionStats"
import EmailSection from "./platform_reports/EmailSection"
import RegisteredOverTimeModal from "./platform_reports/RegisteredOverTimeModal"
import DefaultTooltip from "@layout/tooltips/DefaultTooltip"
import ParticipantTicketTable from "./ticketing/ParticipantTicketTable"
import AddAdminSection from "./admins/AddAdminSection"
import FlipFlopFeatures from "./FlipFlopFeatures";

class UsersDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      googleDwellJson: null,
      participants:  props.participants
    }
    this.updateParticipantCallback = this.updateParticipantCallback.bind(this);
  }

  getTotalRegistrants(opts={}) {
    return this.getFilteredRegistrants(opts).length;
  }

  getFilteredParticipants(opts={}) {
    const { participants } = this.state;
    let tempParticipants = participants.filter(x=> !x.test_flag)
    if(opts.employee){
      tempParticipants = tempParticipants.filter(x=> x.registration_answers['attendeetype'] && x.registration_answers['attendeetype'] != 'Customer')
    }else {
      tempParticipants = tempParticipants.filter(x=>!x.registration_answers['attendeetype'] || x.registration_answers['attendeetype'] == 'Customer')
    }
    return tempParticipants
  }

  getFilteredRegistrants(opts={}) {
    return this.getFilteredParticipants(opts).filter(x=>x.status == "registered")
  }
  getFilteredDeclines(opts={}) {
    return this.getFilteredParticipants(opts).filter(x=>x.status == "declined")
  }

  renderParticipants(opts = {}) {
    let participants = this.getFilteredParticipants();
    if(opts['role']){
      participants = participants.filter(x=>x['type'] == opts['role'])
    }
    return (
      <div className="row">
        {
          opts['role'] == null ?
          <div className="col-xs-4 col-md-5ths">
            <ReportBubble
              title={"All participants"}
              bubbleTooltip={"# of all people in the database"}
              count={participants.filter(x=>x.status !="disallowed").length}
              url={`/admin/reports/all_participant_report?${opts['role'] ? "role="+opts['role'] : ""}`}
            />
          </div>
          :
          <></>
        }
        <div className="col-xs-4 col-md-5ths">
          <ReportBubble
            title={"Approved to invite"}
            bubbleTooltip={"# of all people pre-registered in the database"}
            count={participants.filter(x=>x.status =="preregistered").length}
            url={`/admin/reports/preregistered_report?${opts['role'] ? "role="+opts['role'] : ""}`}
          />
        </div>
        <div className="col-xs-4 col-md-5ths">
          <ReportBubble
            title={"Registered"}
            bubbleTooltip={"# of all people that registered either via broadcast email, individual send or were entered manually"}
            count={participants.filter(x=>x.status =="registered").length}
            url={`/admin/reports/registered_report?${opts['role'] ? "role="+opts['role'] : ""}`}
            background="#74BF4B"
          />
        </div>
        {/* <div className="col-xs-4 col-md-5ths">
          <ReportBubble
            title={"Declined"}
            bubbleTooltip={"# of all customers that declined either via broadcast email, individual send or were entered manually"}
            count={participants.filter(x=>x.status =="declined").length}
            url={`/admin/reports/all_declined_report?${opts['role'] ? "role="+opts['role'] : ""}`}
            background="#818181"
          />
        </div> */}
        <div className="col-xs-4 col-md-5ths">

        </div>
      </div>
    )
  }

  renderEmployeeParticipants() {
    return (
      <div className="row">
        <div className="col-xs-4 col-md-5ths">
          <ReportBubble
            title={"Pre-Registered "}
            bubbleTooltip={"# of all people pre-registered in the database"}
            count={this.getFilteredParticipants({employee: true}).filter(x=>x.status !="disallowed").length}
            url="/admin/reports/preregistered_report?employee=true"
          />
        </div>
        <div className="col-xs-4 col-md-5ths">
          <ReportBubble
            title={"Registered"}
            bubbleTooltip={"# of all people that registered either via broadcast email, individual send or were entered manually"}
            count={this.getFilteredParticipants({employee: true}).filter(x=>x.status =="registered").length}
            url="/admin/reports/registered_report?employee=true"
            background="#74BF4B"
          />
        </div>

        <div className="col-xs-4 col-md-5ths">

        </div>
        <div className="col-xs-12">
          <br/><br/>
          <br/><br/>
        </div>
        <div className="col-xs-12">
          <div className="sg-registation-large-copy" style={{fontSize:"22px"}}>
            Employee Logged In Landing Page Statistics
          </div>
          {this.renderEmployeeLoggedInLandingPageStatistics()}
          <br/>
          <br/>
        </div>

        <div className="col-xs-12">
          <div className="sg-registation-large-copy" style={{fontSize:"22px"}}>
            Employee Registration Page Statistics
          </div>
          {this.renderRegistrationPageLanding({employee: true})}
        </div>
      </div>
    )
  }

  renderInvite() {
    //TODO: Write more DRY
    const { emails} = this.props;
    const invite_email = emails.find(x=>x.id=="invite-round-1")
    console.log(invite_email)
    return (
      <div className="row">
        <div className="col-xs-4 col-md-5ths">
          <ReportBubble
            title={"Registered"}
            count={this.getFilteredRegistrants().length}
            url="/admin/reports/registered_report"
            background="#74BF4C"
          />
        </div>
        <div className="col-xs-4 col-md-5ths">
          <ReportBubble
            title={"Declined"}
            count={this.getFilteredParticipants().filter(x=>x.status =="declined").length}
            url="/admin/reports/all_participant_report?status=declined"
            background="#818181"
          />
        </div>
        <div className="col-xs-4 col-md-5ths">

        </div>
        <div className="col-xs-12">
          <EmailSectionStats
            email={invite_email}
          />
        </div>
      </div>
    )
  }
  // <div className="col-xs-4 col-md-5ths">
  //   <ReportBubble
  //     title={"Recieved"}
  //     count={(invite_email||"").sends.length}
  //     color="#0051AE"
  //     url="/admin/reports/postmark_sent_report?title=You’re Invited to Register"
  //   />
  // </div>
  // <div className="col-xs-4 col-md-5ths">
  //
  //   <a href="/admin/reports/postmark_all_report?title=You’re Invited to Register" target="_blank">
  //     <img src="/images/admin/report-fs.png" className="sg-admin-panel-bubble-image"/>
  //   </a>

  // </div>

  renderCheckbox(value) {
    if(value) {
      return (
        <img src="/images/admin-checkmark.svg" className="sg-admin-table-checkbox" />
      )
    }
  }

  getSmallStyle() {
    return {
      width: 30,
      maxWidth: 30,
      textAlign: "center",
    }
  }

  getTableRegistrants() {
    const { invites_sent, surveys,invite_responses } = this.props;
    let participants = this.getFilteredParticipants();
    participants = participants.map(attendee=>{
      attendee.lower_first_name = attendee && attendee.name_first ? attendee.name_first.toLowerCase() : "";
      attendee.lower_last_name = attendee && attendee.name_last ? attendee.name_last.toLowerCase() : "";
      attendee.lower_company = attendee && attendee.company ? attendee.company.toLowerCase() : "";
      attendee.registered = true;
      attendee.recieved_invite = invite_responses.indexOf(attendee.email.toLowerCase()) != -1
      attendee.attended = this.didUserAttend(attendee.email);
      attendee.evaluation = surveys.map(x=> x.uuid).indexOf(attendee.uuid) != -1;
      attendee.ticket_type = attendee.registration_answers.programname;
      if(attendee.ticket_type == "Day - April 7th") {
        attendee.ticket_type = "Day - Unchosen";
      }
      return attendee;
    })
    return participants;
  }

  getRSVPTableRegistrants() {
    const { invites_sent, surveys,invite_responses, save_the_dates, save_the_dates_postmark, save_the_dates_postmark_bounces } = this.props;
    let participants = this.getFilteredParticipants();
    const stdUsers = [];
    participants.forEach(participant => {
      stdUsers.push({
        email: participant.email,
        name_first: participant.name_first,
        name_last: participant.name_last,
        opt_out: participant.opt_out ? "True" : "False"
      });
    });
    return stdUsers;
  }

  didUserAttend(attendee) {
    return false;
  }

  getTableColumns() {
    const { user } = this.props;
    let allColumns = [
      { title: 'First', field: 'lower_first_name', render: (row) => row.name_first},
      { title: 'Last', field: 'lower_last_name', render: (row) => row.name_last},
      { title: 'Email', field: 'email', render: (row) => row.email},
      { title: 'Company', field: 'lower_company', render: (row) => row.company },
      { title: 'Ticket Type', headerStyle: this.getSmallStyle(), cellStyle: this.getSmallStyle(), field: 'ticket_type', sorting: true},
      { title: 'Status', headerStyle: this.getSmallStyle(), cellStyle: this.getSmallStyle(), field: 'status', sorting: true},
      { title: 'Registered', headerStyle: this.getSmallStyle(), cellStyle: this.getSmallStyle(), field: 'status', sorting: true, render: (row) => this.renderCheckbox(row.status == "registered")},
      { title: 'Declined', headerStyle: this.getSmallStyle(), cellStyle: this.getSmallStyle(), field: 'status', sorting: true, render: (row) => this.renderCheckbox(row.status == "declined")},
      { title: 'Evaluation', headerStyle: this.getSmallStyle(), cellStyle: this.getSmallStyle(), field: 'evaluation', sorting: true, render: (row) => this.renderCheckbox(row.evaluation)},
    ]
    return allColumns;
  }

  getRSVPTableColumns() {
    const { user } = this.props;
    let allColumns = [
      { title: 'First', field: 'name_first', render: (row) => row.name_first},
      { title: 'Last', field: 'name_last', render: (row) => row.name_last},
      { title: 'Email', field: 'email', render: (row) => row.email},
      { title: 'Opt Out', field: 'opt_out', render: (row) => row.opt_out },
    ]
    return allColumns;
  }

  renderDwellTimeReport() {
    const { googleDwellJson } = this.state;
    const tiles = [
      {name: "Dashboard", url:"/", averageTime:0},
      {name: "Agenda", url:"/agenda", averageTime:0},
      {name: "Session Catalog", url:"/pages/meetings", averageTime:0},
      {name: "Partners", url:"/partners", averageTime:0},
      {name: "Speakers", url:"/speakers", averageTime:0},
      {name: "Film Festival", url:"/film_festival", averageTime:0},
      {name: "Session Replays", url:"/session_replays", averageTime:0},
      {name: "Resources", url:"/resources", averageTime:0},
      {name: "Surveys", url:"/surveys", averageTime:0},
      {name: "FAQ", url:"/info", averageTime:0},
    ]

    if(googleDwellJson &&  googleDwellJson.rows) {
      let stats = googleDwellJson.rows
      tiles.map(tile=> {
        const row = stats.find(y=> y[0] == tile.url)
        if(row) {
          tile.averageTime = parseFloat(row[4]).toFixed(0)
        }
        return tile;
      })

      const data_mapped = tiles.map(x => [x.name, x.averageTime])
      return (
        <div className="col-xs-12">
          <h1 className="sg-platform-report-graph-title"> Average Page Dwell Times</h1>
           <ColumnChart xtitle="Page" ytitle="Average Dwell Time (seconds)" color={["#1FBDEB"]} data={data_mapped} />
        </div>
      )
    }
  }

  columnChartDataSet() {
    return {
        borderColor:"rgba(31, 189, 235, 0.5)",
        backgroundColor: "rgba(31, 189, 235, 0.5)",
        lineTension: 0,
        fill: true,
      }
  }

  columnChartDataSetTotalReg() {
    return {
      borderColor: ["rgba(124, 124, 124, 0.5)", "rgba(31, 189, 235, 0.5)"],
      backgroundColor: ["rgba(124, 124, 124, 0.5)", "rgba(31, 189, 235, 0.5)"],
      fill: true,
    }
  }

  getTotalRegistrantsChartData() {
    const registrants = this.getFilteredRegistrants()
    return [
      ["Cisco Employee + Octagon", registrants.filter(x=> x.email.indexOf("@cisco.com") != -1 ||  x.email.indexOf("@octagon.com") != -1).length],
      ["Customer Total", registrants.filter(x=> x.email.indexOf("@cisco.com") == -1 &&  x.email.indexOf("@octagon.com") == -1).length]
    ]
  }

  renderReports() {
    const reports = reportsList()

    const renderedReports = reports.map(x=>(
      <tr>
        <td>
          <span className="sg-admin-user-panel-reports-table-report-name">{x.name}</span>
        </td>
        <td >
          <a className="sg-admin-user-panel-reports-table-download" href={x.url}> Download </a>
        </td>
      </tr>
    ));

    return (
      <>
      <div className="col-xs-12">
        <h1 className="sg-platform-report-graph-title"> Download reports </h1>
        <table className="sg-admin-user-panel-table">
          {renderedReports}
        </table>
      </div>
      </>
    )
  }

  getTotalCustomerTitleChartData() {
    const registrants = this.getFilteredRegistrants().filter(x=> x.email.indexOf("@cisco.com") == -1 &&  x.email.indexOf("@octagon.com") == -1)
    return registrants.map(x=> x.job_title).filter((x,i,t)=>t.indexOf(x) == i).map(x=>[x, registrants.filter(y=>y.job_title == x).length]);
  }

  getOverTime() {
    const registrants = this.getFilteredRegistrants();
    const startDate = new Date("10-24-2022");
    const currentDate = new Date();
    let iDate = startDate; //Iterator Date
    const dateObject = {}
    while(iDate < currentDate) {
      iDate.setDate(iDate.getDate() + 1);
      dateObject[startDate] = registrants.filter(x=> new Date(x.registered_at) <= iDate).length
    }
    return dateObject
  }

  getEmailResponses() {
    const { save_the_dates } = this.props;
    return [
      ["Yes", (save_the_dates).filter(x=>x.status == "interested").length],
      ["No", (save_the_dates).filter(x=>x.status == "declined").length]
    ]
  }

  renderNominations() {
    const { nom_tool_nominations } = this.props;
    return (
      <div className="row">
        <div className="col-xs-4 col-md-2">
          <ReportBubble
            title={"Draft"}
            count={getTotalNomsByNomStatus(nom_tool_nominations, "draft")}
            background={"#CAD1DB"}
            font_color={"#0D274D"}
            type={"nom_bubble"}
          />
        </div>

        <div className="col-xs-4 col-md-2">
          <ReportBubble
            title={"Submitted"}
            count={getTotalNomsByNomStatus(nom_tool_nominations, "submit")}
            background={"#CAD1DB"}
            font_color={"#0D274D"}
            type={"nom_bubble"}
          />
        </div>

        <div className="col-xs-4 col-md-2">
          <ReportBubble
            title={"In Review"}
            count={getTotalNomsByNomStatus(nom_tool_nominations, "in_review")}
            background={"#CAD1DB"}
            font_color={"#0D274D"}
            type={"nom_bubble"}
          />
        </div>

        <div className="col-xs-4 col-md-2">
          <ReportBubble
            title={"Denied"}
            count={getTotalNomsByNomStatus(nom_tool_nominations, "rejected")}
            background={"#CAD1DB"}
            font_color={"#0D274D"}
            type={"nom_bubble"}
          />
        </div>

        <div className="col-xs-4 col-md-2">
          <ReportBubble
            title={"Nominations Approved"}
            count={getTotalNomsByNomStatus(nom_tool_nominations, "approved")}
            background={"#ffffff"}
            font_color={"#0D274D"}
          />
        </div>

        <div className="col-xs-4 col-md-2">
          <ReportBubble
            title={"Total Nominations"}
            count={getTotalNoms(nom_tool_nominations)}
            background={"#CAD1DB"}
            font_color={"#0D274D"}
            type={"nom_bubble"}
          />
        </div>

      </div>
    );
  }

  getEmailFullReportButton(key) {
    return (
      <a href={`/admin/reports/full_activity_report_by_tag?tag=${key}`} target="_blank">
       <img src="/images/admin/full-save-report.png" style={
         {
           height: "42px",
           marginTop: "-20px"
         }
       }/>
      </a>
    )
  }

  getNomToolButton(opts={}) {
    return (
      <>
        <DefaultTooltip
          tooltipId={"sg-admin-panel-nom-tool-button"}
        >
          Click to navigate to the Nomination Tool
        </DefaultTooltip>
        <a href="https://www.nominationtool.com/events/45" target="_blank">
          <img
            data-tip
            data-for="sg-admin-panel-nom-tool-button"
            style={{height:"40px"}}
            src="/images/admin/btn-nom-tool.png"
          />
        </a>
      </>
    )
  }

  getRegisteredOverTimeButton(opts={}) {
    return (
      <>
      <DefaultTooltip
        tooltipId={"sg-admin-panel-registered-over-time"}
      >
        Click to view line graph
      </DefaultTooltip>
      <RegisteredOverTimeModal
        participants={this.getFilteredRegistrants(opts)}
      >
        <img
          data-tip
          data-for="sg-admin-panel-registered-over-time"
          style={{height:"40px"}}
          src="/images/admin/registered-over-time.png"
        />
      </RegisteredOverTimeModal>
      </>
    )
  }

  getRegistrationPageButton() {
    return (
      <>
      <DefaultTooltip
        tooltipId={"sg-admin-panel-registered-registration-page-report"}
      >
      Click to download detailed report
      </DefaultTooltip>
      <a href="/admin/reports/registration_abort_tracking" target="_blank">
        <img
          data-tip
          data-for="sg-admin-panel-registered-registration-page-report"
          style={{height:"40px"}}
          src="/images/admin/registration-tracking-report-button.png"
        />
      </a>
      </>
    )
  }



  renderPublicLandingPageStatistics() {
    const { dashboard_clicks, dashboard_enters } = this.props;
    return (
      <div className="row" style={{color: '#00BCEB'}}>
        <div className="col-xs-12 col-sm-6 col-md-3">
          <div className="sg-admin-panel-statistic">
            <div className="sg-admin-panel-statistic-number">
              {dashboard_enters}
            </div>
            <div className="sg-admin-panel-statistic-label">
              Views
            </div>
            <DefaultTooltip
              tooltipId={"sg-admin-panel-public-landing-views"}
            >
              Click to download detailed report
            </DefaultTooltip>
            <div
              data-tip
              data-for={"sg-admin-panel-public-landing-views"}
              className="sg-admin-panel-statistic-download">
              <a href="/admin/reports/dashboard_visits?log">
                <img src="/images/admin/light-blue-download-report.png"/>
              </a>
            </div>
          </div>
        </div>
        <div className="col-xs-12 col-sm-6 col-md-3">
          <div className="sg-admin-panel-statistic">
            <div className="sg-admin-panel-statistic-number">
              {dashboard_clicks}
            </div>
            <div className="sg-admin-panel-statistic-label">
              Links Clicked
            </div>
            <DefaultTooltip
              tooltipId={"sg-admin-panel-public-landing-links-clicked"}
            >
              Click to download detailed report
            </DefaultTooltip>
            <div
              data-tip
              data-for={"sg-admin-panel-public-landing-links-clicked"}
              className="sg-admin-panel-statistic-download"
            >
              <a href="/admin/reports/dashboard_clicks">
                <img src="/images/admin/light-blue-download-report.png"/>
              </a>
            </div>
          </div>
        </div>
      </div>
    )
  }


  renderLoggedInLandingPageStatistics() {
    const { logged_in_dashboard_clicks, logged_in_dashboard_enters } = this.props;
    return (
      <div className="row" style={{color: '#00BCEB'}}>
        <div className="col-xs-12 col-sm-6 col-md-3">
          <div className="sg-admin-panel-statistic">
            <div className="sg-admin-panel-statistic-number">
              {logged_in_dashboard_enters}
            </div>
            <div className="sg-admin-panel-statistic-label">
              Views
            </div>
            <DefaultTooltip
              tooltipId={"sg-admin-panel-public-landing-views"}
            >
              Click to download detailed report
            </DefaultTooltip>
            <div
              data-tip
              data-for={"sg-admin-panel-public-landing-views"}
              className="sg-admin-panel-statistic-download">
              <a href="/admin/reports/dashboard_visits?key=entered_logged_in_landing_page">
                <img src="/images/admin/light-blue-download-report.png"/>
              </a>
            </div>
          </div>
        </div>
        <div className="col-xs-12 col-sm-6 col-md-3">
          <div className="sg-admin-panel-statistic">
            <div className="sg-admin-panel-statistic-number">
              {logged_in_dashboard_clicks}
            </div>
            <div className="sg-admin-panel-statistic-label">
              Links Clicked
            </div>
            <DefaultTooltip
              tooltipId={"sg-admin-panel-public-landing-links-clicked"}
            >
              Click to download detailed report
            </DefaultTooltip>
            <div
              data-tip
              data-for={"sg-admin-panel-public-landing-links-clicked"}
              className="sg-admin-panel-statistic-download"
            >
              <a href="/admin/reports/dashboard_clicks?key=logged-in-landing-page-click">
                <img src="/images/admin/light-blue-download-report.png"/>
              </a>
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderEmployeeLoggedInLandingPageStatistics() {
    const { employee_logged_in_dashboard_clicks, employee_logged_in_dashboard_enters } = this.props;
    return (
      <div className="row" style={{color: '#00BCEB'}}>
        <div className="col-xs-12 col-sm-6 col-md-3">
          <div className="sg-admin-panel-statistic">
            <div className="sg-admin-panel-statistic-number">
              {employee_logged_in_dashboard_enters}
            </div>
            <div className="sg-admin-panel-statistic-label">
              Views
            </div>
            <DefaultTooltip
              tooltipId={"sg-admin-panel-public-landing-views"}
            >
              Click to download detailed report
            </DefaultTooltip>
            <div
              data-tip
              data-for={"sg-admin-panel-public-landing-views"}
              className="sg-admin-panel-statistic-download">
              <a href="/admin/reports/dashboard_visits?key=entered_logged_in_landing_page&employee=true">
                <img src="/images/admin/light-blue-download-report.png"/>
              </a>
            </div>
          </div>
        </div>
        <div className="col-xs-12 col-sm-6 col-md-3">
          <div className="sg-admin-panel-statistic">
            <div className="sg-admin-panel-statistic-number">
              {employee_logged_in_dashboard_clicks}
            </div>
            <div className="sg-admin-panel-statistic-label">
              Links Clicked
            </div>
            <DefaultTooltip
              tooltipId={"sg-admin-panel-public-landing-links-clicked"}
            >
              Click to download detailed report
            </DefaultTooltip>
            <div
              data-tip
              data-for={"sg-admin-panel-public-landing-links-clicked"}
              className="sg-admin-panel-statistic-download"
            >
              <a href="/admin/reports/dashboard_clicks?key=logged-in-landing-page-click&employee=true">
                <img src="/images/admin/light-blue-download-report.png"/>
              </a>
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderPageLanding(page, opts={}) {
    let { pages, employee_pages} = this.props;
    if(opts.employee){
      pages = employee_pages
    }
    const current_page = pages[`${page}`]
    const tooltipId = `sg-admin-page-landing-${page}-tooltip`

    const toolTipText = opts.tooltip || `# of people who entered page ${page} of the Reg Form`
    const label = opts.label || `Clicked to Page ${page}`
    return (
      <div className="col-xs-12 col-sm-6 col-md-3">
        <DefaultTooltip
          tooltipId={tooltipId}
        >
          {toolTipText}
        </DefaultTooltip>
        <div
          data-tip
          data-for={tooltipId}
          className="sg-admin-panel-statistic"
          style={{
            color: opts.color || "#828993"
          }}
        >
          <div className="sg-admin-panel-statistic-number">
            {current_page}
          </div>
          <div className="sg-admin-panel-statistic-label">
            {label}
          </div>
        </div>
      </div>
    )
  }

  renderRegistrationPageLanding(opts={}) {
    return (
      <div className="row" style={{color: '#828993'}}>
        {this.renderPageLanding(1, opts)}
        {this.renderPageLanding(2, opts)}
        {this.renderPageLanding(3, opts)}
        {this.renderPageLanding(4, {
          color: "#74BF4B",
          label:"Registered",
          tooltip:"# of people who entered the Reg Confirmation Page",
          ...opts
        })}
        <div className="col-xs-12 sg-admin-email-statistic-warning" style={{color: "#828993"}}>
          * Information in this section starts February 7th, 2023.
        </div>
      </div>
    )
  }

  updateParticipantCallback(new_participants) {
    this.setState({
      participants: new_participants
    })
  }

  renderSurveys() {
    const { form_submissions } = this.props;
    const renderedSurveys = form_submissions.map(form =>
      <div className="col-xs-4 col-sm-5ths">
        <ReportBubble
          title={form.title}
          bubbleTooltip={"# of surveys taken"}
          count={form.submissions.forms_submissions.length}
          url={`/admin/reports/survey_report?gid=${form.gid}`}
        />
      </div>
    );
    return (
      <div className="row">
        {renderedSurveys}
      </div>
    );
  }

  renderFrame() {
    const { emails, fields, flipflops }= this.props;
    return (
      <div className="row" style={{margin: 0}}>
        <div className="col-xs-12">

          <AdminOpenablePanel
             header="Participants"
             defaultOpen={true}
             forceOpen={true}
             button={this.getRegisteredOverTimeButton()}
           >
             {this.renderParticipants()}
          </AdminOpenablePanel>

          <AdminOpenablePanel
             header="Analyst Participants"
             defaultOpen={true}
             forceOpen={true}
             button={this.getRegisteredOverTimeButton()}
           >
             {this.renderParticipants({role: 'Analyst'})}
          </AdminOpenablePanel>

          <AdminOpenablePanel
             header="Press Participants"
             defaultOpen={true}
             forceOpen={true}
             button={this.getRegisteredOverTimeButton()}
           >
             {this.renderParticipants({role: 'Press'})}
          </AdminOpenablePanel>

          <AdminOpenablePanel
            header="Surveys"
            defaultOpen={true}
          >
            {this.renderSurveys()}
          </AdminOpenablePanel>

          <AdminOpenablePanel
            header="Email Statistics"
            defaultOpen={true}
          >
            <EmailSection
              email={emails}
              emailList={[{id: 'kbyg-travel', name:'KBYG Travel'}, {id: 'kbyg-no-travel', name:'KBYG No Travel'}, , {id: 'thank-you', name:'Thank You'}]}
              supressRegistration={true}
              registrants={[]}
              declines={[]}
            />
          </AdminOpenablePanel>

          <AdminOpenablePanel
            header="Participant Table"
            defaultOpen={true}
          >
            <ParticipantTicketTable
              participants={this.getFilteredParticipants()}
              fields={fields}
              updateParticipantCallback={this.updateParticipantCallback}
            />
          </AdminOpenablePanel>

          <AdminOpenablePanel
            header="Custom Reports"
            defaultOpen={true}
          >
            <CustomReportTable

            />
          </AdminOpenablePanel>
          <AdminOpenablePanel
             header="Features"
             defaultOpen={true}
             forceOpen={false}
           >
            <FlipFlopFeatures flipflops={flipflops} />
          </AdminOpenablePanel>
          <AdminOpenablePanel
            header="Admins"
            defaultOpen={true}
          >
            <AddAdminSection user_email={this.props.user_email} />
          </AdminOpenablePanel>
        </div>
      </div>
    );
  }

  render() {
    return this.renderFrame();
  }
}

export default UsersDashboard;
