import React from "react";
import PropTypes from "prop-types";
import { fetchAPI } from "@utility/NetworkUtils"
import Select from 'react-select'
class RegistrationEmailValidation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDate: null,
      error: null,
      emailValue: "",
      urlError: props.error,
    }
  }

  renderPickADate() {
    const { features } = this.props;
    const { selectedDate } = this.state;
    const options = [
      {value: 'day-5', label: 'Sunday, February 6, 2022'}
    ]
    if(features && features.pick_a_date) {
      return (
        <>
          <label>Please select the day you would like to join us from the dropdown below.</label>
          <div style={{maxWidth:"600px",margin: "auto"}}>
            <Select
              options={options}
              classNamePrefix='registration-select'
              value={selectedDate}
              onChange={(option) => {
                this.setState({
                  selectedDate: option
                })
              }}
            />
          </div>
          <br/>
        </>
      )
    }
  }

  render() {
    const { urlError, emailValue, error } = this.state;
    return (
      <form onSubmit={this.verifyEmail} className="att-proam-registration-email-validation " style={{textAlign: "center"}}>
        <div className="sg-header-copy">
          We look forward to welcoming you to the Press and Analyst Conference&nbsp;at Cisco Live Amsterdam.
        </div>
        <div className="att-proam-registration-email-validation-error">
        <br/>
        Attendance at the Press and Analyst Conference is by invitation only.
        <br />
        Please ensure you use the same email address as provided to the Cisco team.
        </div>
        <div className="att-proam-registration-email-validation-submit">
          <br/>
          <a href="/users/auth/okta">
            <button
              type="button"
              className="sg-round-button sg-primary-button"
            >
              Register
            </button>
          </a>
          <br/>
          <br/>
        </div>
        {urlError=="attendee-not-found"? (
          <div className="att-proam-registration-email-validation-error">
            Registration for this event is by invitation only.
            <br/>
            If you have reached this page in error or have questions,
            <br/>
            please contact <a href="mailto:clm-pressanalyst@external.cisco.com">clm&#8209;pressanalyst@external.cisco.com</a>.
          </div>
        ):""}
        {urlError=="already-registered"? (
          <div className="att-proam-registration-email-validation-error">
            You’re already registered! If you wish to make changes  to your
            <br/>registration, please contact
            <a href="mailto:clm-pressanalyst@external.cisco.com"> clm&#8209;pressanalyst@external.cisco.com</a>.
          </div>
        ):""}
        <div>

          <br/>
          <br/>


          <br/>
          <br/>
        </div>
      </form>
    );
  }
}

export default RegistrationEmailValidation;
